<template>
    <section :id="sliceId" class="MomentumWall">
        <FillTextEffect
            class="MomentumWall__title"
            :id="sliceId"
            bottom-position="400px"
            :trigger-container="'#' + sliceId"
        >
            <PrismicRichText
                v-if="slice.primary.section_title.length"
                :field="slice.primary.section_title"
            />
        </FillTextEffect>
        <div class="MomentumWall__wrapper">
            <Splide
                :options="{
                    arrows: false,
                    drag: 'free',
                    mediaQuery: 'min',
                    fixedWidth: '15rem',
                    gap: '2rem',
                    padding: { left: '1rem', right: offset },
                    breakpoints: {
                        600: {
                            fixedWidth: '15rem',
                            gap: '3rem',
                            padding: { left: '2rem', right: offset },
                        },
                        900: {
                            padding: offset,
                        },
                    },
                }"
            >
                <template
                    v-for="(rowItem, rowIndex) in groupedRows"
                    :key="index"
                >
                    <SplideSlide>
                        <template v-for="(item, index) in rowItem">
                            <component
                                :is="item.to || item.href ? CustomLink : 'div'"
                                :to="item.to || null"
                                :href="item.href || null"
                                class="MomentumWall__brick-link"
                                :class="`MomentumWall__brick-link-${index + 1}`"
                            >
                                <div class="MomentumWall__brick-head">
                                    <SmartImage
                                        class="MomentumWall__brick-image"
                                        v-if="item.logo?.path"
                                        :url="item.logo.path"
                                        :srcs="
                                            item.logo.dimensions.width
                                                ? [item.logo.dimensions]
                                                : [{ width: 588, height: 331 }]
                                        "
                                        :width="
                                            item.logo.dimensions.width
                                                ? item.logo.dimensions.width
                                                : 140
                                        "
                                        :height="
                                            item.logo.dimensions.height
                                                ? item.logo.dimensions.height
                                                : 60
                                        "
                                        :with-placeholder="false"
                                        :alt="item.logo.alt"
                                    />
                                    <FontIcon
                                        icon="arrow"
                                        color="black"
                                        size="x-small"
                                        class="MomentumWall__brick-arrow"
                                    />
                                </div>
                                <div class="MomentumWall__brick-content">
                                    <h5 class="MomentumWall__brick-title">
                                        {{ item.title }}
                                    </h5>
                                    <p class="MomentumWall__brick-desc">
                                        {{ item.description }}
                                    </p>
                                </div>
                            </component>
                        </template>
                    </SplideSlide>
                </template>
            </Splide>
        </div>
        <div class="drag-proxy"></div>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'
import { CustomLink } from '#components'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css/core'

declare global {
    interface Window {
        gsap: any
    }
}

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.MomentumWallSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const offset = ref('8rem')

const row1 = ref([])
const row2 = ref([])
const sliceId = props.slice.id.replace('$', '-') + '-momentum-wall'

function buildInternalLink(item) {
    let url

    if (item.internal_link.url) {
        url = item.internal_link.url
    } else if (item.case_study.url) {
        url = item.case_study.url
    } else if (item.news_post.url) {
        url = item.case_study.url
    }

    if (item.prefilter_to_brand.uid) {
        url += '?brand=' + item.prefilter_to_brand.uid

        if (item.prefilter_to_capability.uid) {
            url += '&capability=' + item.prefilter_to_capability.uid
        }
    } else if (item.prefilter_to_capability.uid) {
        url += '?capability=' + item.prefilter_to_capability.uid
    }

    return url
}

function buildRow(items) {
    return items
        .filter((item) => !item.isBroken)
        .map((item) => {
            return {
                logo: {
                    path:
                        item.case_study?.data?.brand?.data?.client_logo?.url ||
                        item.prefilter_to_brand?.data?.client_logo?.url,
                    dimensions:
                        item.case_study?.data?.brand?.data?.client_logo
                            ?.dimensions ||
                        item.prefilter_to_brand?.data?.client_logo?.dimensions,
                    alt:
                        item.case_study?.data?.brand?.data?.client_name ||
                        item.prefilter_to_brand?.data?.client_name,
                },
                title:
                    item.custom_title ||
                    (item.case_study.data ? 'Case Study' : '') ||
                    (item.news_post.data ? 'News' : ''),
                description:
                    item.custom_description ??
                    item.case_study?.data?.card_title ??
                    item.case_study?.data?.title ??
                    item.news_post?.data?.title,
                to: buildInternalLink(item),
                href: item.external_link.url,
            }
        })
        .filter((item) => {
            return item.title && item.description
        })
}

if (props.slice.items.length < 8) {
    row1.value = buildRow(props.slice.items)
} else {
    row1.value = buildRow(
        props.slice.items.slice(0, props.slice.items.length / 2),
    )
    row2.value = buildRow(
        props.slice.items.slice(
            props.slice.items.length / 2,
            (props.slice.items.length / 2) * 2,
        ),
    )
}

function splitArrayIntoGroups(array, groupSize) {
    let result = []
    for (let i = 0; i < array.length; i += groupSize) {
        result.push(array.slice(i, i + groupSize))
    }
    return result
}

const allRows = [...row1.value, ...row2.value]
const groupedRows = ref([])
groupedRows.value = splitArrayIntoGroups(allRows, 2).filter((a) => a)
</script>

<style lang="scss">
.MomentumWall {
    --offset: 72px;

    @include grid;

    grid-column: 1 / span 24;
    padding-top: 8rem;

    @include large-up {
        padding-top: 11.25rem;
    }
}

.MomentumWall__title {
    grid-column: 2 / span 22;
    max-width: 48.125rem;
    margin-bottom: 2.5rem;

    h2 {
        @include heading-2;
    }
}

.MomentumWall__wrapper {
    grid-column: 1 / span 24;
    width: 100%;
}

.MomentumWall__brick-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-decoration: none;
    padding: 1.25rem clamp(1.25rem, 1.5vw, 2.5rem);
    aspect-ratio: 12 / 16;
    background-color: var(--black-30);
    border: 0.5px solid var(--black-50);
    transition: background-color 0.3s ease;

    &:hover {
        background-color: var(--lime-green);

        .MomentumWall__brick-arrow {
            opacity: 1;
            transform: translate(5px, -5px);
        }

        .MomentumWall__brick-image {
            height: 3rem;
        }

        .MomentumWall__brick-image,
        .MomentumWall__brick-title,
        .MomentumWall__brick-desc {
            filter: brightness(10%);
        }
    }
}

.MomentumWall__brick-link-1 {
    margin-bottom: 2rem;

    @include medium-up {
        margin-bottom: 3rem;
    }
}

.MomentumWall__brick-link-2 {
    transform: translateX(calc(v-bind(offset) - 3rem));
}

.MomentumWall__brick-head {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.MomentumWall__brick-image {
    height: 2.5rem;
    max-width: 6.25rem;
    transition: height 0.3s ease;
}

.MomentumWall__brick-arrow {
    opacity: 0;
    transform: translate(-30px, 20px);
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    margin-left: auto;
}

.MomentumWall__brick-content {
    margin-top: auto;
}

.MomentumWall__brick-title {
    @include label-sm;

    margin-bottom: 0.5rem;
}

.MomentumWall__brick-desc {
    @include body-sm;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.MomentumWall__brick-image,
.MomentumWall__brick-title,
.MomentumWall__brick-desc {
    filter: brightness(100%);
    transition: all 0.3s ease;
}
</style>
